<template>
  <div class="w-full md:w-5/6 relative z-40 px-1">
    <form @submit.prevent="handleSubmit" @click="clickOnSearchBar()" class="z-50">
      <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">{{
        $t('Search')
      }}</label>
      <div class="relative" :class="isLandingPage ? 'block' : 'hidden md:block'">
        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <svg
            class="w-4 h-4 ml-2 text-gray-500 dark:text-Text-Grey_Secondary"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
            />
          </svg>
        </div>
        <input
          ref="searchInput"
          type="search"
          id="default-search"
          :value="query"
          @input="handleInput"
          @keydown="handleKeydown"
          @focus="showSuggestions = true"
          autocomplete="off"
          class="no-clear-button h-12 block w-full py-4 ps-12 text-[3.2vw] sm:text-lg text-gray-900 border border-gray-300 rounded-full bg-gray-50 focus:border-Tourscanner_blue-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          :class="{ 'dark:border-yellow-500 border-2': searchVersion === 'V2' }"
          :placeholder="$t('Search and select a destination')"
          required
        />
        <button
          type="submit"
          class="text-white absolute end-[9px] bottom-[7px] right-[7px] bg-gradient-to-r from-ts_blue_to to-ts_blue_from hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 flex items-center justify-center min-w-[80px]"
          @onclick="searchForQuery"
        >
          <span v-if="!isLoading">{{ $t('Search') }}</span>
          <div v-else class="animate-spin h-[18px] w-4 border-t-2 border-b-2 border-white rounded-full"></div>
        </button>
      </div>

      <div :class="isLandingPage ? 'hidden' : 'block md:hidden'">
        <button
          @click="showModal = true"
          class="border-1 text-gray-800 dark:text-white border-2 border-solid border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-2 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
        >
          <img src="/img/icons/search_B.svg" class="icon dark:filter dark:invert dark:invert-1 w-5 h-5" />
        </button>
      </div>
    </form>

    <!-- Chat component -->
    <Chat
      id="chat-container"
      :class="`hidden md:flex lg:flex-col absolute bg-white dark:bg-zinc-800 border border-gray-300 dark:border-gray-600 rounded-xl mt-2 w-full overflow-y-auto shadow-lg z-50 `"
      :style="{ height: chatMaxHeight, width: resultsWidth + 'px' }"
      v-if="showChat"
      :startQuestion="startQuestion"
      @closeChat="stopChatSession"
    />

    <ul
      id="results-container"
      v-else-if="
        (!isSearchEmpty && showSuggestions && searchVersion === 'V1') ||
        (showSuggestions && suggestions.length > 0 && searchVersion === 'V2') ||
        (popularSearches.length > 0 && !isSearchEmpty)
      "
      class="absolute f bg-white dark:bg-zinc-800 border border-gray-300 dark:border-gray-600 rounded-xl p-1 mt-2 w-full max-h-fit shadow-lg z-50"
      :style="{ width: resultsWidth + 'px' }"
    >
      <li
        v-for="(suggestion, index) in suggestions"
        :key="suggestion.id"
        :class="{ 'bg-gray-200 dark:bg-gray-700 rounded-lg': index === selectedSuggestionIndex }"
        class="p-2 hover:bg-gray-200 dark:hover:bg-gray-700 cursor-pointer flex items-center rounded-lg mb-1"
        @click="selectSearch(index)"
        :id="index"
      >
        <div class="flex items-center mr-2">
          <img v-if="suggestion.image" class="icon rounded w-20 h-20 object-cover" :src="suggestion.image" />
          <img
            v-else
            class="icon dark:filter dark:invert dark:invert-1 w-5 h-5"
            :src="`/img/icons/${getIconName(suggestion.role)}.svg`"
          />
        </div>
        <div class="flex flex-col cursor-pointer">
          <label class="block leading-tight cursor-pointer">{{ suggestion.name }}</label>
          <label class="text-sm block leading-tight cursor-pointer">{{ suggestion.subtitle }}</label>
          <!--If the suggestion is activity then show the stars-->
          <div v-if="suggestion.role === 'activity'" class="flex items-center mt-2">
            <StarRating
              :reviews_count="suggestion.reviews_count"
              :all_filled="true"
              :rating="suggestion.reviews_avg"
            ></StarRating>
          </div>
        </div>
      </li>

      <li
        v-if="!isSearchEmpty"
        :class="{
          'bg-gray-200 dark:bg-gray-700 hove': selectedSuggestionIndex === suggestions.length,
        }"
        class="p-2 cursor-pointer flex items-center rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700"
        @click="searchForQuery"
      >
        <div class="flex items-center mr-2">
          <img src="/img/icons/travel_explore.svg" class="icons mr-2 dark:filter dark:invert dark:invert-1" />
          {{ $t('Search all results for') }}
          <strong class="ml-1"
            ><i>{{ query }}</i></strong
          >
        </div>
      </li>
      <div v-if="searchVersion == 'V2'" class="flex flex-row justify-end w-full pt-3 pb-1 pr-2 text-right mr-4">
        <button
          id="go-to-chat-button"
          @click.stop="startChatSession"
          class="hover:bg-gray-200 dark:hover:bg-gray-700 flex flex-row py-2 px-4 rounded-full cursor-pointer"
        >
          <span>Chat</span
          ><img src="/img/icons/chat_paste_go.svg" class="ml-3 icon dark:filter dark:invert dark:invert-1" />
        </button>
      </div>
    </ul>
    <ul
      v-if="isSearchEmpty && !popularSearchesEmpty && showSuggestions"
      class="absolute f bg-white dark:bg-zinc-800 border border-gray-300 dark:border-zinc-600 rounded-lg mt-2 w-full max-h-fit shadow-lg z-50 p-3"
      :style="{ width: resultsWidth + 'px' }"
    >
      <li v-for="(category, categoryIndex) in popularSearches" :key="categoryIndex">
        <div v-if="category.links.length > 0" class="p-2">
          <h3 class="font-semibold text-gray-700 dark:text-gray-300 mb-2">{{ category.title }}</h3>
          <ul class="flex flex-nowrap md:flex-wrap overflow-x-auto md:overflow-x-hidden">
            <li
              v-for="(link, linkIndex) in category.links"
              :key="linkIndex"
              class="p-2 px-5 hover:bg-gray-200 border-gray-300 dark:border-zinc-600 dark:hover:bg-zinc-600 cursor-pointer flex items-center rounded-full border mr-1 mt-2 leading-tight text-md"
              @click.stop="selectSuggestion(link.title, link.url)"
            >
              {{ link.title }}
            </li>
          </ul>
        </div>
      </li>
    </ul>

    <!-- Mobile Fullscreen Modal -->
    <!---------------->
    <!---------------->
    <!---------------->
    <!---------------->
    <!---------------->
    <div v-if="showModal" id="modal-bg" class="fixed inset-0 z-50 flex bg-zinc-800 items-end overflow-hidden bottom-0">
      <div
        id="modal-content"
        class="bg-white dark:bg-zinc-900 w-full flex flex-col shadow-xl border-t-2 dark:border-gray-400 dark:shadow-gray-300"
        :style="{ height: chatMaxHeight, overflow: 'hidden' }"
      >
        <div class="flex flex-row justify-between items-center">
          <button @click="closeModal" v-if="!showChat" class="text-gray-500 hover:text-gray-900 py-4 px-4">
            <img src="/img/icons/close.svg" class="icon dark:filter dark:invert dark:invert-1" />
          </button>
          <button
            v-if="!isLoading && !showChat"
            id="go-to-chat-button"
            @click.stop="startChatSession"
            class="hover:bg-gray-200 dark:hover:bg-gray-700 flex py-2 px-4 rounded-full cursor-pointer"
          >
            <span>Chat</span
            ><img src="/img/icons/chat_paste_go.svg" class="ml-3 icon dark:filter dark:invert dark:invert-1" />
          </button>
        </div>
        <div class="flex-grow flex-col overflow-y-auto" v-if="!showChat">
          <ul
            v-if="
              (!isSearchEmpty && showSuggestions && searchVersion === 'V1') ||
              (showSuggestions && suggestions.length > 0 && searchVersion === 'V2') ||
              (popularSearches.length > 0 && !isSearchEmpty)
            "
            class="px-4 dark:bg-zinc-800"
          >
            <li
              v-for="(suggestion, index) in suggestions"
              :key="suggestion.id"
              class="cursor-pointer flex items-center first:pt-4 last:pb-4 py-2"
              @click.stop="selectSearch(index)"
            >
              <div class="flex items-center mr-2">
                <img
                  v-if="suggestion.image && suggestion.role == 'activity'"
                  class="icon rounded w-16 h-16 object-cover"
                  :src="suggestion.image"
                />
                <img
                  v-else
                  class="icon dark:filter dark:invert dark:invert-1 w-5 h-5"
                  :src="`/img/icons/${getIconName(suggestion.role)}.svg`"
                />
              </div>
              <div class="flex flex-col flex-1">
                <label class="block leading-tight">{{ suggestion.name }}</label>
                <label class="text-sm block leading-tight">{{ suggestion.subtitle }}</label>
                <!--If the suggestion is activity then show the stars-->
                <div v-if="suggestion.role === 'activity'" class="flex items-center mt-2">
                  <StarRating
                    :reviews_count="suggestion.reviews_count"
                    :all_filled="true"
                    :rating="suggestion.reviews_avg"
                  ></StarRating>
                </div>
              </div>
            </li>
            <li
              v-if="!isSearchEmpty"
              class="cursor-pointer flex items-center"
              :class="suggestions.length ? 'py-4' : 'pt-0'"
              @click.stop="searchForQuery"
            >
              <div class="flex items-start mr-2">
                <img src="/img/icons/travel_explore.svg" class="icon mr-2 dark:filter dark:invert dark:invert-1" />
                <span class="leading-tight text-md"
                  >{{ $t('Search all results for') }}
                  <strong class=""
                    ><i>{{ query }}</i></strong
                  ></span
                >
              </div>
            </li>
          </ul>
          <ul
            v-if="isSearchEmpty && popularSearches.length > 0"
            class="absolute f bg-white dark:bg-zinc-800 mt-5 w-full max-h-fit z-50"
          >
            <li v-for="(category, categoryIndex) in popularSearches" :key="categoryIndex">
              <div v-if="category.links.length > 0" class="mb-5">
                <h3 class="font-semibold text-xl text-gray-700 dark:text-gray-300 px-4">{{ category.title }}</h3>
                <ul class="flex flex-nowrap overflow-x-auto gap-2">
                  <li
                    v-for="(link, linkIndex) in category.links"
                    :key="linkIndex"
                    class="px-3 py-1 text-center hover:bg-gray-200 dark:hover:bg-zinc-600 cursor-pointer inline-flex items-center rounded-full border mt-2 whitespace-nowrap leading-tight first:ml-4"
                    @click.stop="selectSuggestion(link.title, link.url)"
                  >
                    {{ link.title }}
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <Chat
          id="chat-container"
          class="h-full"
          v-else="showChat"
          :startQuestion="startQuestion"
          @closeChat="stopChatSession"
        />

        <div class="flex mb-2 px-2 flex-col bg-transparent bg-opacity-80" v-if="!showChat">
          <div
            v-if="searchVersion == 'V2'"
            class="flex flex-row justify-end w-full pt-1 pb-1 pr-2 text-right mr-4"
          ></div>
          <input
            type="search"
            :value="query"
            @input="handleInput"
            @focus="onInputFocus"
            @blur="onInputBlur"
            @keydown="handleKeydown"
            autocomplete="off"
            class="no-clear-button h-12 block w-full ps-6 text-xl text-gray-900 border border-gray-300 rounded-full bg-gray-50 focus:border-Tourscanner_blue-500 dark:bg-zinc-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search for cities or attractions"
            id="mobile-search"
            style="font-size: 1rem"
            required
          />
          <div v-if="isLoading" class="absolute bottom-[23px] right-3 flex items-center pr-3">
            <div class="animate-spin h-5 w-5 border-t-2 border-b-2 border-black dark:border-white rounded-full"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import debounce from 'lodash.debounce';
import { nextTick, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import StarRating from './StarRating.vue';

const log = useLogger();

const searchInput = ref(null);
const resultsWidth = ref(0);
const mixpanel = useMixpanel();
const chatMaxHeight = ref('90%'); // Default height when keyboard is hidden
const windowHeightWithKeyboard = ref(0);

const isKeyboardVisible = ref(false);

const onViewportResize = () => {
  if (window.innerWidth <= 768) {
    const viewportHeight = window.visualViewport.height;
    chatMaxHeight.value = viewportHeight + 'px';
  }
};

const onInputFocus = () => {
  isKeyboardVisible.value = true;
  chatMaxHeight.value = windowHeightWithKeyboard.value + 'px';
};

const onInputBlur = () => {
  isKeyboardVisible.value = false;
  chatMaxHeight.value = '90%';
};

const calculateChatMaxHeight = () => {
  if (window.innerWidth > 768) {
    const searchInput = document.getElementById('default-search');
    if (searchInput) {
      const inputRect = searchInput.getBoundingClientRect();
      const inputBottom = inputRect.bottom;
      chatMaxHeight.value = `calc(100vh - ${inputBottom}px - 50px)`; // Adjust as needed
    }
  } else {
    // Mobile devices
    const viewportHeight = window.visualViewport.height;
    chatMaxHeight.value = viewportHeight + 'px';
  }
};

const props = defineProps({
  initialQuery: {
    type: String,
    default: '',
  },
  popularSearches: {
    type: Array,
    default: () => [],
  },
  cityID: {
    type: Number,
    default: () => null,
  },
});

const query = ref(props.initialQuery);
const suggestions = ref([]);
const selectedSuggestionIndex = ref(0);
const previousQuery = ref('');
const showSuggestions = ref(false);
const showModal = ref(false);
const isLoading = ref(false);
const searchTimeout = ref(null);
const { locale } = useI18n();
const currentLang = locale.value;
const localePath = useLocalePath();
const route = useRoute();

//Check if the url contains the parameter "features=preview"
const isPreview = route.query.features === 'preview';
const searchVersion = 'V2';
const showChat = ref(false);

const isSearchEmpty = computed(() => query.value.trim() === '');

const handleInput = (event) => {
  query.value = event.target.value;
  clearTimeout(searchTimeout.value);
  debounceFetchSuggestions();
};

// Update this watcher to react to initialQuery changes
watch(
  () => props.initialQuery,
  (newValue) => {
    query.value = newValue;
  },
);

//Watch for in the popularSearches array
watch(props.popularSearches, (newValue) => {
  props.popularSearches.value = newValue;
});

//Check if any link is in the sections and if sections is > 0
const popularSearchesEmpty = computed(() => {
  let empty = true;
  for (const section of props.popularSearches) {
    if (section.links.length > 0) {
      empty = false;
      break;
    }
  }
  return empty;
});
watch(props.cityID, (newValue) => {
  props.cityID.value = newValue;
});

// Check if the path is homepage or search page
const isLandingPage = computed(() => {
  const pathName = route.name;
  if (!pathName) return false;
  return pathName?.includes('search') || pathName?.includes('index');
});

const fetchSuggestions = async () => {
  if (searchVersion != 'V2') {
    if (query.value.trim() === '' || query.value === previousQuery.value) {
      isLoading.value = false;
      return;
    }
  }

  isLoading.value = true;

  // Set a timeout to wait for 1 more second before making the search
  searchTimeout.value = setTimeout(async () => {
    mixpanel.track('search', {
      content: query.value,
      query: query.value,
      fromCityID: props.cityID,
      fromPath: route.path,
      type: 'autocomplete',
      lang: currentLang,
    });

    previousQuery.value = query.value;
    const { data } = await useFetch(`/api/suggestion`, {
      params: {
        query: query.value,
        city: props.cityID,
        lang: currentLang,
        fromPath: route.path,
        version: searchVersion,
      },
    });

    suggestions.value = data.value ?? [];
    isLoading.value = false;
  }, 1000);
};

const debounceFetchSuggestions = debounce(() => {
  clearTimeout(searchTimeout.value);
  fetchSuggestions();
}, 500);

const clickOnSearchBar = () => {
  if (window.innerWidth < 768 && showModal.value == false) {
    showModal.value = true;
    document.body.style.overflow = 'hidden'; // Disable scrolling
    window.scrollTo(0, 0); // Scroll to the top
    nextTick(() => {
      const searchMobile = document.getElementById('mobile-search');
      searchMobile?.focus();
      searchMobile?.select();
    });
  }
};

watch(
  () => showModal.value,
  () => {
    if (showModal.value) {
      lockScroll();
    } else {
      unlockScroll();
    }
  },
);

const handleKeydown = (event) => {
  if (event.key === 'ArrowDown') {
    event.preventDefault();
    if (selectedSuggestionIndex.value < suggestions.value.length) {
      selectedSuggestionIndex.value++;
    }
  } else if (event.key === 'ArrowUp') {
    event.preventDefault();
    if (selectedSuggestionIndex.value > 0) {
      selectedSuggestionIndex.value--;
    }
  } else if (event.key === 'Enter') {
    event.preventDefault();

    //When pressing enter always go on the full search
    searchForQuery();

    // if (selectedSuggestionIndex.value < suggestions.value.length) {
    //   selectSearch(selectedSuggestionIndex.value);
    // } else {
    // }
  } else if (event.key === 'Escape') {
    showSuggestions.value = false;
  }
};

const startQuestion = ref('');

const selectSearch = async (index) => {
  const selected = suggestions.value[index];
  log.info('Selected search:', selected);
  mixpanel.track('search', {
    content: selected.name + ' ' + selected.subtitle + ' ROLE:' + selected.role,
    query: selected.url,
    fromCityID: props.cityID,
    fromPath: route.path,
    type: 'tap-result',
    lang: currentLang,
  });

  if (selected.role === 'chat') {
    startChatSession(selected);
  } else {
    if (showModal.value) {
      closeModal();
    }
    if (!selected.url.includes('tourscanner.com')) {
      await navigateTo(localePath(selected.url), { external: true });
    } else {
      await navigateTo(localePath(selected.url));
    }
  }
};

const lockScroll = () => {
  const scrollY = window.scrollY;
  document.body.style.position = 'fixed';
  document.body.style.top = `-${scrollY}px`;
  document.body.style.width = '100%';
  document.body.classList.add('modal-open');
};

const unlockScroll = () => {
  const scrollY = document.body.style.top;
  document.body.style.position = '';
  document.body.style.top = '';
  document.body.style.width = '';
  window.scrollTo(0, parseInt(scrollY || '0') * -1);
  document.body.classList.remove('modal-open');
};

watch(
  () => showModal.value,
  (newValue) => {
    if (newValue) {
      lockScroll();
    } else {
      unlockScroll();
    }
  },
);

const startChatSession = (chatSuggestion) => {
  startQuestion.value = chatSuggestion.name;
  showChat.value = true;
  lockScroll();
};

const stopChatSession = () => {
  showChat.value = false;
  showSuggestions.value = true;
  unlockScroll();
};

const selectSuggestion = async (title, link) => {
  mixpanel.track('search', {
    content: title,
    query: link,
    fromCityID: props.cityID,
    fromPath: route.path,
    type: 'tap-suggestion',
    lang: currentLang,
  });
  log.info('Selected suggestion:', link);
  if (showModal.value == true) {
    closeModal();
  }
  document.body.style.overflow = 'auto'; // Enable scrolling
  await navigateTo(localePath(link));
};

const handleSubmit = () => {
  log.info('Search submitted:', query.value);
  suggestions.value = [];
  showSuggestions.value = false;
};

const emit = defineEmits(['newSearch']);
const searchForQuery = async () => {
  log.info('Search for:', query.value);
  mixpanel.track('search', {
    content: query.value,
    query: query.value,
    fromCityID: props.cityID,
    fromPath: route.path,
    type: 'full-search',
    lang: currentLang,
  });
  if (route.name.includes('search')) {
    closeModal();
    showSuggestions.value = false;
    document.activeElement.blur(); // This will remove focus from the active element (search bar)
    emit('newSearch', query.value);
  } else {
    log.info('Navigating to search page');
    navigateTo(localePath(`/search?q=${query.value}`));
  }
};

const closeModal = () => {
  showModal.value = false;
  showSuggestions.value = false;
  document.body.style.overflow = 'auto'; // Enable scrolling
};

const handleClickOutside = (event) => {
  if (showModal.value) {
    return;
  }
  const searchBar = document.getElementById('default-search');
  const chatContainer = document.getElementById('chat-container');
  const resultsContainer = document.getElementById('results-container');
  if (chatContainer) {
    const hideSuggestions =
      searchBar && !searchBar.contains(event.target) && chatContainer && !chatContainer.contains(event.target);
    if (hideSuggestions) {
      showSuggestions.value = false;
    }
  } else if (resultsContainer) {
    const hideSuggestions =
      searchBar && !searchBar.contains(event.target) && resultsContainer && !resultsContainer.contains(event.target);
    if (hideSuggestions) {
      showSuggestions.value = false;
    }
  } else {
    const hideSuggestions = searchBar && !searchBar.contains(event.target);
    if (hideSuggestions) {
      showSuggestions.value = false;
    }
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
  updateResultsWidth();
  calculateChatMaxHeight();
  window.addEventListener('resize', updateResultsWidth);
  window.addEventListener('resize', calculateChatMaxHeight);
  windowHeightWithKeyboard.value = window.visualViewport.height;
  window.visualViewport.addEventListener('resize', onViewportResize);

  if (searchVersion == 'V2' && suggestions.value.length == 0) {
    fetchSuggestions();
  }
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
  window.removeEventListener('resize', updateResultsWidth);
  window.removeEventListener('resize', calculateChatMaxHeight);
});

const updateResultsWidth = () => {
  if (!searchInput.value) return;
  resultsWidth.value = searchInput?.value?.offsetWidth ?? 0;
};

const getIconName = (role) => {
  if (role === 'mun') {
    return 'apartment';
  } else if (role === 'acr' || role === 'ctr') {
    return 'flag';
  } else if (role === 'idt') {
    return 'bookmark_flag';
  } else if (role === 'loc') {
    return 'location_on';
  } else if (role === 'cat') {
    return 'category_b';
  } else if (role === 'blog') {
    return 'book_2';
  } else if (role === 'popular') {
    return 'diamond';
  } else if (role === 'chat') {
    return 'chat_paste_go';
  } else {
    return 'search';
  }
};
</script>

<style scoped>
@media screen and (max-width: 490px) {
  #default-search {
    font-size: 4vw;
  }
}
/* Ensure modal takes full screen and disable body scrolling */
body.modal-open {
  overflow: hidden;
}

.fixed.inset-0 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
}

.no-clear-button::-webkit-search-cancel-button,
.no-clear-button::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

/* Add styles for loading indicator */
.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Add this CSS */
body.modal-open {
  overflow: hidden;
}

#modal-bg {
  overflow: hidden; /* Prevents scrolling inside the modal background */
  position: fixed;
  inset: 0;
  overflow: hidden;
  display: flex;
  align-items: flex-end; /* Align content to the bottom */
}
</style>
