import payload_plugin_ALLXFGgbCP from "/Users/alberto/Repos/TourScanner/web-1/website/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/Users/alberto/Repos/TourScanner/web-1/website/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/alberto/Repos/TourScanner/web-1/website/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/alberto/Repos/TourScanner/web-1/website/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/Users/alberto/Repos/TourScanner/web-1/website/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/Users/alberto/Repos/TourScanner/web-1/website/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/alberto/Repos/TourScanner/web-1/website/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_zbacBAXhj0 from "/Users/alberto/Repos/TourScanner/web-1/website/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_KR1HBZs4kY from "/Users/alberto/Repos/TourScanner/web-1/website/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/alberto/Repos/TourScanner/web-1/website/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_pNVVgm7Ud2 from "/Users/alberto/Repos/TourScanner/web-1/website/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_plugin_8cq4FnzXKb from "/Users/alberto/Repos/TourScanner/web-1/website/.nuxt/vuefire-plugin.mjs";
import plugin_client_UYiXMU8ZyN from "/Users/alberto/Repos/TourScanner/web-1/website/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import sentry_client_VdOeuw25Mb from "/Users/alberto/Repos/TourScanner/web-1/website/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/Users/alberto/Repos/TourScanner/web-1/website/.nuxt/sentry-client-config.mjs";
import plugin_z0941Mskea from "/Users/alberto/Repos/TourScanner/web-1/website/node_modules/mixpanel-nuxt/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/Users/alberto/Repos/TourScanner/web-1/website/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_OVoKJro5pc from "/Users/alberto/Repos/TourScanner/web-1/website/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import chunk_reload_client_UciE0i6zes from "/Users/alberto/Repos/TourScanner/web-1/website/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import numberFormat_CZU1VKV9BH from "/Users/alberto/Repos/TourScanner/web-1/website/plugins/numberFormat.js";
import logger_client_NoN5FB4OWs from "/Users/alberto/Repos/TourScanner/web-1/website/plugins/logger.client.ts";
import datetimeFormat_K7GtNq2Psx from "/Users/alberto/Repos/TourScanner/web-1/website/plugins/datetimeFormat.js";
import tapfiliate_client_WCRmUgF9sR from "/Users/alberto/Repos/TourScanner/web-1/website/plugins/tapfiliate.client.js";
export default [
  payload_plugin_ALLXFGgbCP,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_zbacBAXhj0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_pNVVgm7Ud2,
  vuefire_plugin_8cq4FnzXKb,
  plugin_client_UYiXMU8ZyN,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_z0941Mskea,
  i18n_yfWm7jX06p,
  plugin_client_OVoKJro5pc,
  chunk_reload_client_UciE0i6zes,
  numberFormat_CZU1VKV9BH,
  logger_client_NoN5FB4OWs,
  datetimeFormat_K7GtNq2Psx,
  tapfiliate_client_WCRmUgF9sR
]