import { default as _91member_name_93LsxYuXmCwSMeta } from "/Users/alberto/Repos/TourScanner/web-1/website/pages/about/[member_name].vue?macro=true";
import { default as indexwYpwuWdbHAMeta } from "/Users/alberto/Repos/TourScanner/web-1/website/pages/about/index.vue?macro=true";
import { default as companyI1ZmjdPzaxMeta } from "/Users/alberto/Repos/TourScanner/web-1/website/pages/company.vue?macro=true";
import { default as contactszrew2c3PRZMeta } from "/Users/alberto/Repos/TourScanner/web-1/website/pages/contacts.vue?macro=true";
import { default as cookieszvftTVKh4KMeta } from "/Users/alberto/Repos/TourScanner/web-1/website/pages/cookies.vue?macro=true";
import { default as destinationsrPMMAjkzETMeta } from "/Users/alberto/Repos/TourScanner/web-1/website/pages/destinations.vue?macro=true";
import { default as faqU8qTwqBQXAMeta } from "/Users/alberto/Repos/TourScanner/web-1/website/pages/faq.vue?macro=true";
import { default as _91_46_46_46slug_93xWlwUAj0uMMeta } from "/Users/alberto/Repos/TourScanner/web-1/website/pages/forward/[...slug].vue?macro=true";
import { default as indexFIVZMzkvlCMeta } from "/Users/alberto/Repos/TourScanner/web-1/website/pages/index.vue?macro=true";
import { default as _91product_id_93Wj5I98RONfMeta } from "/Users/alberto/Repos/TourScanner/web-1/website/pages/p/[product_id].vue?macro=true";
import { default as list_45your_45experiencexzqqYearmNMeta } from "/Users/alberto/Repos/TourScanner/web-1/website/pages/partner/list-your-experience.vue?macro=true";
import { default as privacy2ETFdzmnETMeta } from "/Users/alberto/Repos/TourScanner/web-1/website/pages/privacy.vue?macro=true";
import { default as _91category_name_93VKFtpsRCGnMeta } from "/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_n]/c/[category_name].vue?macro=true";
import { default as _91identity_name_93owoNjY1wuYMeta } from "/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_n]/i/[identity_name].vue?macro=true";
import { default as _91tag_name_93zrp2ulgBPXMeta } from "/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_n]/t/[tag_name].vue?macro=true";
import { default as _91city_name_93vk4ncHFvufMeta } from "/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_name].vue?macro=true";
import { default as searchbq6zz3MTKBMeta } from "/Users/alberto/Repos/TourScanner/web-1/website/pages/search.vue?macro=true";
import { default as terms_45of_45service0kEmsKqu11Meta } from "/Users/alberto/Repos/TourScanner/web-1/website/pages/terms-of-service.vue?macro=true";
import { default as component_45stubGK2BPcx1tEMeta } from "/Users/alberto/Repos/TourScanner/web-1/website/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubGK2BPcx1tE } from "/Users/alberto/Repos/TourScanner/web-1/website/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about-member_name___en___default",
    path: "/about-us/:member_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/about/[member_name].vue").then(m => m.default || m)
  },
  {
    name: "about-member_name___en",
    path: "/en/about-us/:member_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/about/[member_name].vue").then(m => m.default || m)
  },
  {
    name: "about-member_name___fr",
    path: "/fr/a-propos-de/:member_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/about/[member_name].vue").then(m => m.default || m)
  },
  {
    name: "about-member_name___it",
    path: "/it/chi-siamo/:member_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/about/[member_name].vue").then(m => m.default || m)
  },
  {
    name: "about-member_name___de",
    path: "/de/ueber-uns/:member_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/about/[member_name].vue").then(m => m.default || m)
  },
  {
    name: "about-member_name___es",
    path: "/es/acerca-de/:member_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/about/[member_name].vue").then(m => m.default || m)
  },
  {
    name: "about-member_name___pt",
    path: "/pt/sobre-nos/:member_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/about/[member_name].vue").then(m => m.default || m)
  },
  {
    name: "about-member_name___nl",
    path: "/nl/over-ons/:member_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/about/[member_name].vue").then(m => m.default || m)
  },
  {
    name: "about___en___default",
    path: "/about-us",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "about___en",
    path: "/en/about-us",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "about___fr",
    path: "/fr/a-propos-de",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "about___it",
    path: "/it/chi-siamo",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "about___de",
    path: "/de/ueber-uns",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "about___es",
    path: "/es/acerca-de",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "about___pt",
    path: "/pt/sobre-nos",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "about___nl",
    path: "/nl/over-ons",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "company___en___default",
    path: "/company-details",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/company.vue").then(m => m.default || m)
  },
  {
    name: "company___en",
    path: "/en/company-details",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/company.vue").then(m => m.default || m)
  },
  {
    name: "company___fr",
    path: "/fr/coordonn%25C3%25A9es-de-la-soci%25C3%25A9t%25C3%25A9",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/company.vue").then(m => m.default || m)
  },
  {
    name: "company___it",
    path: "/it/dati-societari",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/company.vue").then(m => m.default || m)
  },
  {
    name: "company___de",
    path: "/de/impressum",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/company.vue").then(m => m.default || m)
  },
  {
    name: "company___es",
    path: "/es/informaci%25C3%25B3n-de-la-empresa",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/company.vue").then(m => m.default || m)
  },
  {
    name: "company___pt",
    path: "/pt/dados-da-empresa",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/company.vue").then(m => m.default || m)
  },
  {
    name: "company___nl",
    path: "/nl/bedrijfsgegevens",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/company.vue").then(m => m.default || m)
  },
  {
    name: "contacts___en___default",
    path: "/contact-us",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "contacts___en",
    path: "/en/contact-us",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "contacts___fr",
    path: "/fr/contactez-nous",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "contacts___it",
    path: "/it/contattaci",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "contacts___de",
    path: "/de/kontaktiere-uns",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "contacts___es",
    path: "/es/contacto",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "contacts___pt",
    path: "/pt/contacte-nos",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "contacts___nl",
    path: "/nl/contacteer-ons",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "cookies___en___default",
    path: "/cookies-policy",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: "cookies___en",
    path: "/en/cookies-policy",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: "cookies___fr",
    path: "/fr/politique-en-mati%25C3%25A8re-de-cookies",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: "cookies___it",
    path: "/it/informativa-sui-cookie",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: "cookies___de",
    path: "/de/cookie-richtlinie",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: "cookies___es",
    path: "/es/cookie-policy",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: "cookies___pt",
    path: "/pt/polit%25C3%25ADca-de-cookies",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: "cookies___nl",
    path: "/nl/cookiebeleid",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: "destinations___en___default",
    path: "/destinations",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/destinations.vue").then(m => m.default || m)
  },
  {
    name: "destinations___en",
    path: "/en/destinations",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/destinations.vue").then(m => m.default || m)
  },
  {
    name: "destinations___fr",
    path: "/fr/destinations",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/destinations.vue").then(m => m.default || m)
  },
  {
    name: "destinations___it",
    path: "/it/destinazioni",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/destinations.vue").then(m => m.default || m)
  },
  {
    name: "destinations___de",
    path: "/de/reiseziele",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/destinations.vue").then(m => m.default || m)
  },
  {
    name: "destinations___es",
    path: "/es/destinos",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/destinations.vue").then(m => m.default || m)
  },
  {
    name: "destinations___pt",
    path: "/pt/destinos",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/destinations.vue").then(m => m.default || m)
  },
  {
    name: "destinations___nl",
    path: "/nl/bestemmingen",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/destinations.vue").then(m => m.default || m)
  },
  {
    name: "faq___en___default",
    path: "/faq",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___en",
    path: "/en/faq",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___fr",
    path: "/fr/faq",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___it",
    path: "/it/faq",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___de",
    path: "/de/faq",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___es",
    path: "/es/faq",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___pt",
    path: "/pt/faq",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___nl",
    path: "/nl/faq",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "forward-slug___en___default",
    path: "/forward/:slug(.*)*",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/forward/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "forward-slug___en",
    path: "/en/forward/:slug(.*)*",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/forward/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "forward-slug___fr",
    path: "/fr/forward/:slug(.*)*",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/forward/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "forward-slug___it",
    path: "/it/forward/:slug(.*)*",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/forward/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "forward-slug___de",
    path: "/de/forward/:slug(.*)*",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/forward/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "forward-slug___es",
    path: "/es/forward/:slug(.*)*",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/forward/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "forward-slug___pt",
    path: "/pt/forward/:slug(.*)*",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/forward/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "forward-slug___nl",
    path: "/nl/forward/:slug(.*)*",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/forward/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "index___en___default",
    path: "/",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___it",
    path: "/it",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___pt",
    path: "/pt",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "p-product_id___en___default",
    path: "/p/:product_id()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/p/[product_id].vue").then(m => m.default || m)
  },
  {
    name: "p-product_id___en",
    path: "/en/p/:product_id()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/p/[product_id].vue").then(m => m.default || m)
  },
  {
    name: "p-product_id___fr",
    path: "/fr/p/:product_id()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/p/[product_id].vue").then(m => m.default || m)
  },
  {
    name: "p-product_id___it",
    path: "/it/p/:product_id()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/p/[product_id].vue").then(m => m.default || m)
  },
  {
    name: "p-product_id___de",
    path: "/de/p/:product_id()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/p/[product_id].vue").then(m => m.default || m)
  },
  {
    name: "p-product_id___es",
    path: "/es/p/:product_id()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/p/[product_id].vue").then(m => m.default || m)
  },
  {
    name: "p-product_id___pt",
    path: "/pt/p/:product_id()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/p/[product_id].vue").then(m => m.default || m)
  },
  {
    name: "p-product_id___nl",
    path: "/nl/p/:product_id()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/p/[product_id].vue").then(m => m.default || m)
  },
  {
    name: "partner-list-your-experience___en___default",
    path: "/partner/list-your-experience",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/partner/list-your-experience.vue").then(m => m.default || m)
  },
  {
    name: "partner-list-your-experience___en",
    path: "/en/partner/list-your-experience",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/partner/list-your-experience.vue").then(m => m.default || m)
  },
  {
    name: "partner-list-your-experience___fr",
    path: "/fr/partenaire/publier-une-offre",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/partner/list-your-experience.vue").then(m => m.default || m)
  },
  {
    name: "partner-list-your-experience___it",
    path: "/it/partner/aggiungi-la-tua-esperienza",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/partner/list-your-experience.vue").then(m => m.default || m)
  },
  {
    name: "partner-list-your-experience___de",
    path: "/de/partner/angebote-inserieren",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/partner/list-your-experience.vue").then(m => m.default || m)
  },
  {
    name: "partner-list-your-experience___es",
    path: "/es/asociado/publica-tu-experiencia",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/partner/list-your-experience.vue").then(m => m.default || m)
  },
  {
    name: "partner-list-your-experience___pt",
    path: "/pt/parceiros/adicionar-experiencias",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/partner/list-your-experience.vue").then(m => m.default || m)
  },
  {
    name: "partner-list-your-experience___nl",
    path: "/nl/nl/partner/promoot-jouw-activiteiten",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/partner/list-your-experience.vue").then(m => m.default || m)
  },
  {
    name: "privacy___en___default",
    path: "/privacy-policy",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "privacy___en",
    path: "/en/privacy-policy",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "privacy___fr",
    path: "/fr/politique-sur-la-vie-priv%25C3%25A9e",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "privacy___it",
    path: "/it/informativa-sulla-privacy",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "privacy___de",
    path: "/de/datenschutz-bestimmungen",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "privacy___es",
    path: "/es/pol%25C3%25ADtica-de-privacidad",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "privacy___pt",
    path: "/pt/polit%25C3%25ADca-de-privicidade",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "privacy___nl",
    path: "/nl/privacybeleid",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "s-city_n-c-category_name___en___default",
    path: "/s/:city_n()/c/:category_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_n]/c/[category_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_n-c-category_name___en",
    path: "/en/s/:city_n()/c/:category_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_n]/c/[category_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_n-c-category_name___fr",
    path: "/fr/s/:city_n()/c/:category_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_n]/c/[category_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_n-c-category_name___it",
    path: "/it/s/:city_n()/c/:category_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_n]/c/[category_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_n-c-category_name___de",
    path: "/de/s/:city_n()/c/:category_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_n]/c/[category_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_n-c-category_name___es",
    path: "/es/s/:city_n()/c/:category_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_n]/c/[category_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_n-c-category_name___pt",
    path: "/pt/s/:city_n()/c/:category_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_n]/c/[category_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_n-c-category_name___nl",
    path: "/nl/s/:city_n()/c/:category_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_n]/c/[category_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_n-i-identity_name___en___default",
    path: "/s/:city_n()/i/:identity_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_n]/i/[identity_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_n-i-identity_name___en",
    path: "/en/s/:city_n()/i/:identity_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_n]/i/[identity_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_n-i-identity_name___fr",
    path: "/fr/s/:city_n()/i/:identity_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_n]/i/[identity_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_n-i-identity_name___it",
    path: "/it/s/:city_n()/i/:identity_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_n]/i/[identity_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_n-i-identity_name___de",
    path: "/de/s/:city_n()/i/:identity_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_n]/i/[identity_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_n-i-identity_name___es",
    path: "/es/s/:city_n()/i/:identity_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_n]/i/[identity_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_n-i-identity_name___pt",
    path: "/pt/s/:city_n()/i/:identity_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_n]/i/[identity_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_n-i-identity_name___nl",
    path: "/nl/s/:city_n()/i/:identity_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_n]/i/[identity_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_n-t-tag_name___en___default",
    path: "/s/:city_n()/t/:tag_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_n]/t/[tag_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_n-t-tag_name___en",
    path: "/en/s/:city_n()/t/:tag_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_n]/t/[tag_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_n-t-tag_name___fr",
    path: "/fr/s/:city_n()/t/:tag_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_n]/t/[tag_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_n-t-tag_name___it",
    path: "/it/s/:city_n()/t/:tag_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_n]/t/[tag_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_n-t-tag_name___de",
    path: "/de/s/:city_n()/t/:tag_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_n]/t/[tag_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_n-t-tag_name___es",
    path: "/es/s/:city_n()/t/:tag_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_n]/t/[tag_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_n-t-tag_name___pt",
    path: "/pt/s/:city_n()/t/:tag_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_n]/t/[tag_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_n-t-tag_name___nl",
    path: "/nl/s/:city_n()/t/:tag_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_n]/t/[tag_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_name___en___default",
    path: "/s/:city_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_name___en",
    path: "/en/s/:city_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_name___fr",
    path: "/fr/s/:city_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_name___it",
    path: "/it/s/:city_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_name___de",
    path: "/de/s/:city_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_name___es",
    path: "/es/s/:city_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_name___pt",
    path: "/pt/s/:city_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_name].vue").then(m => m.default || m)
  },
  {
    name: "s-city_name___nl",
    path: "/nl/s/:city_name()",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/s/[city_name].vue").then(m => m.default || m)
  },
  {
    name: "search___en___default",
    path: "/search",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___fr",
    path: "/fr/search",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___it",
    path: "/it/search",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/de/search",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/es/search",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___pt",
    path: "/pt/search",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___nl",
    path: "/nl/search",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service___en___default",
    path: "/terms-of-service",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service___en",
    path: "/en/terms-of-service",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service___fr",
    path: "/fr/conditions-d%2527utilisation",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service___it",
    path: "/it/termini-di-servizio",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service___de",
    path: "/de/nutzungsbedingungen",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service___es",
    path: "/es/condiciones-del-servicio",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service___pt",
    path: "/pt/termos-de-servi%25C3%25A7o",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service___nl",
    path: "/nl/voorwaarden",
    component: () => import("/Users/alberto/Repos/TourScanner/web-1/website/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: component_45stubGK2BPcx1tEMeta?.name,
    path: "/terms/",
    component: component_45stubGK2BPcx1tE
  },
  {
    name: component_45stubGK2BPcx1tEMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubGK2BPcx1tE
  },
  {
    name: component_45stubGK2BPcx1tEMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubGK2BPcx1tE
  },
  {
    name: component_45stubGK2BPcx1tEMeta?.name,
    path: "/en-US-sitemap.xml",
    component: component_45stubGK2BPcx1tE
  },
  {
    name: component_45stubGK2BPcx1tEMeta?.name,
    path: "/fr-FR-sitemap.xml",
    component: component_45stubGK2BPcx1tE
  },
  {
    name: component_45stubGK2BPcx1tEMeta?.name,
    path: "/it-IT-sitemap.xml",
    component: component_45stubGK2BPcx1tE
  },
  {
    name: component_45stubGK2BPcx1tEMeta?.name,
    path: "/de-DE-sitemap.xml",
    component: component_45stubGK2BPcx1tE
  },
  {
    name: component_45stubGK2BPcx1tEMeta?.name,
    path: "/es-ES-sitemap.xml",
    component: component_45stubGK2BPcx1tE
  },
  {
    name: component_45stubGK2BPcx1tEMeta?.name,
    path: "/pt-PT-sitemap.xml",
    component: component_45stubGK2BPcx1tE
  },
  {
    name: component_45stubGK2BPcx1tEMeta?.name,
    path: "/nl-NL-sitemap.xml",
    component: component_45stubGK2BPcx1tE
  }
]