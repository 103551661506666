// composables/useTrackingId.js

import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

// Define the set of URL parameters to track (including UTM parameters)
const TRACKED_PARAMS = ['gclid', 'ref', 'fbclid'];

function generateRandomId() {
  const today = new Date().toISOString().split('T')[0].replace(/-/g, '');
  return Math.random().toString(36).substring(2, 36) + Date.now().toString(36) + today;
}

export function useTrackingId() {
  const mixpanel = useMixpanel(); // Initialize your Mixpanel instance
  const route = useRoute();
  const campaignCookie = useCookie('campaignV2', { maxAge: 365 * 24 * 60 * 60 }); // 1 year expiry
  const trackingId = ref(null);
  const isLoading = ref(true);

  // Helper function to parse URL parameters into an object
  function getUrlParams(route) {
    const params = {};
    const queryString = route.fullPath.split('?')[1] || '';
    const pairs = queryString.split('&').filter((pair) => pair);
    let tmCount = 0;

    pairs.forEach((pair) => {
      const [key, value] = pair.split('=').map(decodeURIComponent);
      if (TRACKED_PARAMS.includes(key)) {
        params[key] = value || '';
      } else if (key.startsWith('tm_') && tmCount < 5) {
        // Remove 'tm_' prefix and replace it with tapfiliate_tm
        let tmKey = key.substring(3);
        tmKey = 'tapfiliate_tm_' + tmKey;
        params[tmKey] = value || '';
        tmCount++;
      }
    });

    return params;
  }

  function getAllUrlParams(route) {
    const params = {};
    const queryString = route.fullPath.split('?')[1] || '';
    const pairs = queryString.split('&').filter((pair) => pair);
    let tmCount = 0;

    pairs.forEach((pair) => {
      const [key, value] = pair.split('=').map(decodeURIComponent);
      params[key] = value || '';
    });

    return params;
  }

  const initializeTracking = async () => {
    isLoading.value = true;

    try {
      const currentParams = getUrlParams(route);
      const allParams = getAllUrlParams(route); //These parameters are not saved in the DB but sent to Mixpanel
      let currentDate = new Date().toISOString().split('T')[0];
      let storedData = {};
      let shouldUpdate = false;

      // Retrieve or create the campaign cookie
      if (campaignCookie.value) {
        try {
          storedData = campaignCookie.value;
        } catch (error) {
          console.error('Failed to parse campaign cookie:', error);
          storedData = { id: generateRandomId(), params: {} };
        }
      } else {
        storedData = { id: generateRandomId(), params: {} };
      }

      // Check and update params
      for (const key in currentParams) {
        if (currentParams[key]) {
          let shouldUpdateCookies =
            !storedData.params[key] ||
            storedData.params[key].lastSeen < currentDate ||
            currentParams[key] != storedData.params[key].value;
          if (shouldUpdateCookies) {
            shouldUpdate = true;
            storedData.params[key] = {
              value: currentParams[key],
              lastSeen: currentDate,
              count: (storedData.params[key]?.count || 0) + 1,
            };
          }
        }
      }

      // Handle Tapfiliate tracking
      await new Promise((resolve) => {
        if (window.tap && window.tap.vids) {
          resolve();
        } else {
          const checkTap = setInterval(() => {
            if (window.tap && window.tap.vids) {
              clearInterval(checkTap);
              resolve();
            }
          }, 100);
        }
      });

      if (window.tap && window.tap.vids && window.tap.vids[0]) {
        const tapfiliateId = window.tap.vids[0];
        if (storedData.params['tapfiliateId']?.value !== tapfiliateId) {
          shouldUpdate = true;
          storedData.params['tapfiliateId'] = {
            value: tapfiliateId,
            lastSeen: currentDate,
            count: (storedData.params['tapfiliateId']?.count || 0) + 1,
          };
        }
      }

      const tapfiliateRef = currentParams.ref;
      if (tapfiliateRef && (!storedData.params['ref'] || storedData.params['ref'].value !== tapfiliateRef)) {
        shouldUpdate = true;
        storedData.params['ref'] = {
          value: tapfiliateRef,
          lastSeen: currentDate,
          count: (storedData.params['ref']?.count || 0) + 1,
        };
      }

      // Save Facebook click ID (fbclid)
      if (
        currentParams.fbclid &&
        (!storedData.params['fbclid'] || storedData.params['fbclid'].value !== currentParams.fbclid)
      ) {
        shouldUpdate = true;
        storedData.params['fbclid'] = {
          value: currentParams.fbclid,
          lastSeen: currentDate,
          count: (storedData.params['fbclid']?.count || 0) + 1,
        };
      }

      // Save Facebook click ID cookie (fbc)
      if (document.cookie.includes('_fbc=')) {
        const fbc = document.cookie
          .split(';')
          .find((c) => c.trim().startsWith('_fbc='))
          .split('=')[1];
        if (!storedData.params['fbc'] || storedData.params['fbc'].value !== fbc) {
          shouldUpdate = true;
          storedData.params['fbc'] = {
            value: fbc,
            lastSeen: currentDate,
            count: (storedData.params['fbc']?.count || 0) + 1,
          };
        }
      }

      // Save Facebook browser ID (fbp) if the user has already seen a Facebook ad (if it has the fbc or fbclid)
      if (document.cookie.includes('_fbp=') && (storedData.params['fbc'] || storedData.params['fbclid'])) {
        const fbp = document.cookie
          .split(';')
          .find((c) => c.trim().startsWith('_fbp='))
          .split('=')[1];
        if (!storedData.params['fbp'] || storedData.params['fbp'].value !== fbp) {
          shouldUpdate = true;
          storedData.params['fbp'] = {
            value: fbp,
            lastSeen: currentDate,
            count: (storedData.params['fbp']?.count || 0) + 1,
          };
        }
      }

      if (shouldUpdate) {
        // Save updated data to cookie
        campaignCookie.value = JSON.stringify(storedData);

        // Prepare data for API call
        const apiData = {
          id: storedData.id,
          params: Object.entries(storedData.params).reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
          }, {}),
          distinct_id: mixpanel.get_distinct_id(),
          lastSeen: currentDate,
        };

        // Send data to API
        try {
          const response = await $fetch('/api/setCampaign', {
            method: 'POST',
            body: apiData,
          });
          console.log('Campaign data updated successfully:', response);
        } catch (error) {
          console.error('Error updating campaign data:', error);
        }
      }

      trackingId.value = '';
      if (Object.values(storedData.params).length > 0) {
        trackingId.value += `|_TS_|${storedData.id}|_TS_|`;
      }
      if (mixpanel) {
        trackingId.value += '~mp~' + mixpanel.get_distinct_id();
      }

      //If the url has GTTD_product_id, GTTD_ads, GTTD_funnel, GTTD_source, GTTD_src set it to the mixpanel user as super properties once
      mixpanel.register_once({
        GTTD_product_id: allParams.GTTD_product_id,
        GTTD_ads: allParams.GTTD_ads,
        GTTD_funnel: allParams.GTTD_funnel,
        GTTD_source: allParams.GTTD_source,
        GTTD_src: allParams.GTTD_src,
        gclid: storedData.params.gclid?.value,
        ref: storedData.params.ref?.value,
        tapfiliateId: storedData.params.tapfiliateId?.value,
        fbclid: storedData.params.fbclid?.value,
        fbc: storedData.params.fbc?.value,
        fbp: storedData.params.fbp?.value,
        tapfiliateTM1: storedData.params.tapfiliate_tm_1?.value,
        tapfiliateTM2: storedData.params.tapfiliate_tm_2?.value,
        tapfiliateTM3: storedData.params.tapfiliate_tm_3?.value,
        tapfiliateTM4: storedData.params.tapfiliate_tm_4?.value,
        tapfiliateTM5: storedData.params.tapfiliate_tm_5?.value,
        trackingId: trackingId.value,
      });

      if ((route.fullPath.split('?')[1] || '').includes('view_campaign_id') == true || process.dev) {
        console.debug('Campaign data ID:', storedData.id);
        console.debug('Campaign data params:', JSON.stringify(storedData.params));
        console.debug('Current params:', currentParams);
        console.debug('Current date:', currentDate);
        console.debug('Tracking ID:', trackingId.value);
        console.debug('Mixpanel:', mixpanel);
      }
    } catch (error) {
      console.error('Error during tracking initialization:', error);
    } finally {
      isLoading.value = false;
    }
  };

  onMounted(() => {
    if (typeof window !== 'undefined') {
      initializeTracking();
    }
  });

  watch(
    () => route.query,
    () => {
      if (typeof window !== 'undefined') {
        initializeTracking();
      }
    },
    { deep: true },
  );

  return {
    trackingId,
    isLoading,
  };
}
